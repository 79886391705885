<template>
  <div class="state_box">
    <div class="cotegory">
      <el-dropdown @command="handleCommand" placement="bottom">
        <span>
          {{state}}<i class="iconfont icon-papa-sanjiao_xia" style="margin-left: 10px;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command='0'>全部</el-dropdown-item>
          <el-dropdown-item command='1'>已预约</el-dropdown-item>
          <el-dropdown-item command='2'>已完成</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="grade">
      <div>
        <p class="title">总课时</p>
        <p class="num">
          {{
            accountInfo.remaining_count +
            accountInfo.expired_count +
            accountInfo.completed_count +
            accountInfo.booked_count
          }}
        </p>
      </div>
      <span></span>
      <div>
        <p class="title">剩余课时<i class="iconfont icon-papa-fasongshibai" v-if="accountInfo.has_expiring_soon"></i><strong class="caution" v-if="accountInfo.has_expiring_soon">有课时即将过期<b></b></strong></p>
        <p class="num">{{accountInfo.remaining_count}}</p>
      </div>
      <span></span>
      <div>
        <p class="title">失效课时</p>
        <p class="num">{{accountInfo.expired_count}}</p>
      </div>
      <span></span>
      <div>
        <p class="title">已预约课时</p>
        <p class="num">{{accountInfo.booked_count}}</p>
      </div>
      <span></span>
      <div>
        <p class="title">已完成课时</p>
        <p class="num">{{accountInfo.completed_count}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import stateJs from './headerState'
export default stateJs
</script>

<style lang="less" scoped>
  ::v-deep .el-dropdown {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  ::v-deep .el-dropdown-menu, .el-popper {
    width: 100px !important;
    text-align: center !important;
    border-radius: 8px !important;
  }
  * {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
  .state_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    padding-right: 45px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    .cotegory {
      padding-top: 10px;
      width: 172px;
      height: 40px;
      border-right: 1px solid #EBEBEB;
    }
    .grade {
      display: flex;
      align-items: center;
      width: 662px;
      height: 54px;
      span {
        width: 1px;
        height: 28px;
        background: #EBEBEB;
      }
      div {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          position: relative;
          display: flex;
          justify-content: center;
          margin-bottom: 5px;
          font-size: 14px;
          color: rgba(0,0,0,0.7);
          i {
            display: inline-block;
            margin-top: 4px;
            margin-left: 3px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            color: #29D087;
            &:hover {
              cursor: pointer;
            }
          }
          .caution {
            padding: 0 8px;
            position: absolute;
            top: -26px;
            right: -15px;
            width: 100px;
            height: 24px;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            border-radius: 12px;
            z-index: 99;
            background-color: #29D087;
            b {
              position: absolute;
              bottom: -2px;
              left: 47px;
              width: 7px;
              height: 7px;
              background-color: #29D087;
              transform: rotate(45deg);
              
            }
          }
        }

        .num {
          font-size: 24px;
          color: rgba(0,0,0,0.9);
          font-weight: 700;
        }
      }
    }
  }
</style>