<template>
  <div class="state_box">
    <div class="cotegory">
      <el-dropdown @command="handleCommand" placement="bottom">
        <span>
          {{ planItem.category&&planItem.category.abbr || scoreList&&scoreList[0]&&scoreList[0].category&&scoreList[0].category.abbr}}<i class="iconfont icon-papa-sanjiao_xia" style="margin-left: 10px;"></i>
        </span>
        <el-dropdown-menu slot="dropdown" v-if="scoreList.length>0">
          <el-dropdown-item :command=item v-for="item in scoreList" :key="item.id">{{item&&item.category&&item.category.abbr}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="grade">
      <div class="last">
        <p class="title">上次练习分数</p>
        <p class="num">{{ changeLastScore || lastScore}}</p>
      </div>
      <span></span>
      <div class="max">
        <p class="title">最高练习分数</p>
        <p class="num">{{ planItem&&planItem.max_score >= 0 ? planItem&&planItem.max_score + '.0' : '' || maxScore}}</p>
      </div>
      <span></span>
      <div class="avg">
        <p class="title">平均练习分数</p>
        <p class="num">{{ changeAvgScore || avgScore}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import stateJs from './headerState'
export default stateJs
</script>

<style lang="less" scoped>


  ::v-deep .el-dropdown {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  ::v-deep .el-dropdown-menu, .el-popper {
    width: 100px !important;
    text-align: center !important;
    border-radius: 8px !important;
  }
  * {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
  .state_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    padding-right: 45px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    .cotegory {
      padding-top: 10px;
      width: 172px;
      height: 40px;
      border-right: 1px solid #EBEBEB;
    }
    .grade {
      display: flex;
      align-items: center;
      width: 662px;
      height: 54px;
      span {
        width: 1px;
        height: 28px;
        background: #EBEBEB;
      }
      div {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 14px;
          margin-bottom: 5px;
          color: rgba(0,0,0,0.7);
        }

        .num {
          font-size: 24px;
          color: rgba(0,0,0,0.9);
          font-weight: 700;
        }
      }
    }
  }
</style>
