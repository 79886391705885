<template>
  <div class="open_box">
    <div class="record_box">
      <div class="record" v-for="(item, index) in openRecordData" :key="index">
        <div class="title_box">
          <p class="title">{{ item.channel_source }}</p>
          <p class="num">
            <span
              v-if="
                item.expired_at * 1000 - 1296000000 < new Date().getTime() &&
                !item.is_void &&
                item.remaining_count !== 0 &&
                item.expired_at * 1000 > new Date().getTime()
              "
              >有次数即将过期</span
            >{{ item.purchase_count }}次
          </p>
        </div>
        <div class="time">
          <p>
            上课时间：<span>{{ createdFn(item) }}</span>
          </p>
          <p>
            已使用<strong>{{
              item.purchase_count - item.remaining_count
            }}</strong
            >次
          </p>
        </div>
        <div class="time">
          <p>
            过期时间：<span>{{ expiredFn(item) }}</span>
          </p>
          <p v-if="item.is_void && item.remaining_count !== 0">
            已作废<strong>{{ item.remaining_count }}</strong
            >次
          </p>
          <p
            v-else-if="
              !item.is_void &&
              item.expired_at * 1000 < new Date().getTime() &&
              item.remaining_count !== 0
            "
          >
            已过期<strong>{{ item.remaining_count }}</strong
            >次
          </p>
          <p v-else-if="item.remaining_count !== 0">
            待使用<strong>{{ item.remaining_count }}</strong
            >次
          </p>
          <p v-else></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    openRecordData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    createdFn(row) {
      return moment(row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss");
    },

    expiredFn(row) {
      return moment(row.expired_at * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.open_box {
  padding: 20px;
  width: 100%;
  height: calc(100% - 50px);
  border-radius: 12px;

  .record_box {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    .record {
      padding: 16px 20px;
      margin-bottom: 12px;
      width: 884px;
      height: 137px;
      background-color: #f5f7fa;
      border-radius: 12px;
      &:last-child {
        margin-bottom: 0;
      }

      .title_box {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        margin-bottom: 18px;
        width: 100%;
        height: 37px;
        font-weight: 700;
        border-bottom: 1px solid #e2e2e2;
        span {
          display: inline-block;
          margin-right: 8px;
          text-align: center;
          line-height: 24px;
          width: 108px;
          height: 24px;
          font-size: 12px;
          color: #f85251;
          border-radius: 12px;
          border: 1px solid #f85251;
        }
      }
      .time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        p {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.3);
          span {
            color: rgba(0, 0, 0, 0.7);
          }
          strong {
            font-weight: 700;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.9);
            margin: 0 4px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .record_box::-webkit-scrollbar {
    display: none;
  }
}
</style>
