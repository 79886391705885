<template>
  <div>
    <el-dialog
      :visible.sync="showRaise"
      width="480px"
      @close="handleClose">
      <template #title>
        <p class="title">{{itemList.category}}练习</p>
        <p class="raise_tiem">{{evaluateTime}}</p>
      </template>
      <span>为{{itemList.teacher}}老师的打分</span>
      <div class="star">
        <el-rate v-model="evaluateObj.comment_score" disabled></el-rate>
      </div>
      <div style="margin: 20px 0 20px; padding-bottom: 40px; border-bottom: 1px solid #EBEBEB">
        <el-input
          type="textarea"
          autosize
          readonly
          v-model="evaluateObj.comment_content"
          show-word-limit
        >
        </el-input>
      </div>
      <div style="margin-bottom: 20px;">
        <p>对老师的印象</p>
        <div class="stamp_box">
          <div class="stamp stampColor"  v-for="(item, index) in evaluateObj.tags" :key="index">{{ item }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { trainingPlansApi } from '@/api/newTrainingSpeaking/newTrainingSpeaking.js'
import moment from 'moment'
export default {
  props:{
    showRaise: {
      type: Boolean
    },
    itemList: {
      type: Object
    }
  },
  data() {
    return {
      evaluateObj: {}
    }
  },
  mounted() {
    this.getEvaluate()
  },
  computed: {
    evaluateTime() {
      return moment(this.itemList.commented_at * 1000).format('MM月DD日 HH:mm:ss')
    }
  },
  methods:{
    handleClose() {
      this.$emit('closeShow', false)
    },

    getEvaluate() {
      trainingPlansApi('get', this.itemList.id,{}, (res) => {
        console.log(res)
        this.evaluateObj = res
      })
    }
  }
}
</script>

<style lang="less" scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 12px;
    margin-top: 22vh !important;
    padding: 30px;
  }
  ::v-deep .el-dialog__header {
    text-align: left;
    margin-bottom: 20px;
    padding: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #EBEBEB;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0,0,0,0.9);
  }

  ::v-deep .el-dialog__footer {
    text-align: center;
    padding: 0;
  }

  ::v-deep .el-dialog__close, .el-icon, .el-icon-close {
    color: #000;
    font-size: 24px;
    font-weight: 700;
  }

  ::v-deep .el-rate__icon {
    font-size: 42px;
  }

  ::v-deep .el-textarea__inner {
    background: #F4F7FA;
    border-radius: 8px !important;
    resize: none;
    border: 1px solid transparent !important;
  }

  ::v-deep .el-input__count {
    bottom: -23px !important;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: rgba(0,0,0,0.9);
    line-height: 28px;
  }

  .raise_tiem {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0,0,0,0.6);
  }

  .submit {
    width: 120px;
    height: 48px; 
    font-size: 16px;
    line-height: 24px;
    border-radius: 24px
  }

  .star {
    margin: 8px auto 20px;
    width: 248px;
    height: 40px;
  }

  .stamp_box {
    margin-top: 8px;
    width: 100%;
    .stamp {
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 8px;
      padding: 0 16px;
      height: 30px;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      color: rgba(0,0,0,0.3);
      border: 1px solid #ccc;
      border-radius: 15px;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }

    .stampColor {
      color: #fff;
      background-color: #29D087;
      border: 1px solid transparent;
    }
  }
</style>