<template>
  <div>
    <el-dialog
      title="信息填写"
      :visible.sync="messageIsShow"
      width="480px"
      @close="handleClose">
      <div class="teacher_message">
        <p>预约老师：{{currentTeacher.name}}</p>
        <p>预约时间：{{formatMoment(choseDate,'MM月DD日',true)}} {{chooseTimeItem.range}}</p>
      </div>
      <p class="explain">为了保障课程预约及使用，请填写你的微信号及英文名</p>
      <div class="input_wx">
        <el-input v-model="wxMark" placeholder="请输入你的微信号"></el-input>
        <el-input v-model="enName" placeholder="请输入你的英文名"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="nextSetup">下一步</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatMoment } from '@/utils/utils'
import { userInfoApi } from '@/api/newTrainingSpeaking/newTrainingSpeaking.js'
export default {
  props: {
    messageIsShow: {
      type: Boolean
    },

    currentTeacher: {
      type: Object,
      required: true
    },

    choseDate: {
      type: Number,
      required: true
    },

    chooseTimeItem: {
      type: Object,
      required: true
    },

  },
   data() {
      return {
        wxMark: '',
        enName: ''
      }
   },
   created() {
    userInfoApi('get', '', (res) => {
      console.log(res)
      this.wxMark = res.wechat
      this.enName = res.en_name
    })
   },
   methods:{
    handleClose() {
      this.$emit('offPop', false)
      this.wxMark = ''
      this.enName = ''
    },
    nextSetup() {
      if(this.wxMark && this.enName) {
        let params = {
          en_name: this.enName,
          wechat: this.wxMark
        }
        userInfoApi('put', params)
        this.$emit('nextOffPop', false)
        this.wxMark = ''
        this.enName = ''
      }else {
        this.$message.warning('请输入微信号或英文名')
      }
    },
    formatMoment
   }
}
</script>

<style scoped lang="less">
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .teacher_message {
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    line-height: 22px;
    text-align: left;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #EBEBEB ;

  }

  .explain {
    margin-bottom: 12px;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    text-align: left;
  }

  .input_wx {
    margin-bottom: 20px;
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  

  ::v-deep .el-dialog {
    padding: 30px;
    border-radius: 12px;
  }

  ::v-deep .el-dialog__title {
    font-size: 20px !important;
  } 

  ::v-deep .el-dialog__header {
    padding: 0 0 20px !important;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 700;
    border-bottom: 1px solid #EBEBEB ;
    color: rgba(0,0,0,0.9);
    
  }

  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }

  ::v-deep .el-dialog__footer {
    padding: 0;
    text-align: center;
  }

  ::v-deep .el-dialog__close {
      color: #000 !important;
      font-size: 24px;
      font-weight: 700;
  }

  ::v-deep .el-input__inner {
    background-color: #F4F7FA;
    border-radius: 4px;
    border: 1px solid transparent !important;
  }

  ::v-deep .el-button--primary {
    padding: 12px 44px;
    background: #29D087;
    border-radius: 24px;
    font-size: 16px !important;
    line-height: 24px;
  }
</style>