import Fetch from '@/utils/fetch.js';

const myAccountInfo = (data, fn, errorFn, dataLevel) => {
    new Fetch('training/speaking/account', 'get', data, fn, errorFn, dataLevel);
};
const trainingPlansApi = (data, fn, errorFn, dataLevel = 'v2') => {
    new Fetch('training/speaking/plans', 'get', data, fn, errorFn, dataLevel);
};
const trainingSchedules = (id, data, fn, errorFn, dataLevel = 'v2') => {
    new Fetch(
        `training/speaking/plans/${id}/schedules`,
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
const trainingPlansTeacher = (id, data, fn, errorFn, dataLevel) => {
    new Fetch(
        `training/speaking/plans/${id}/teachers`,
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
const trainingAppointmentListApi = (data, fn, errorFn, dataLevel = 'v2') => {
    new Fetch(
        'training/speaking/appointments',
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
const trainingAppointmentCreateApi = (
    id,
    data,
    fn,
    errorFn,
    dataLevel = 'v2'
) => {
    new Fetch(
        `training/speaking/plans/${id}/appointments`,
        'post',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
const trainingAppointmentInfoApi = (
    id,
    data,
    fn,
    errorFn,
    dataLevel = 'v2'
) => {
    new Fetch(
        `training/speaking/appointments/${id}`,
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
const trainCancelAppointmentApi = (id, data, fn, errorFn, dataLevel = 'v2') => {
    new Fetch(
        `training/speaking/appointments/${id}/cancel`,
        'put',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
const trainingFeedBackApi = (id, data, fn, errorFn, dataLevel = 'v2') => {
    new Fetch(
        `training/speaking/appointments/${id}/feedback`,
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
const trainingCanAppointment = (id, data, fn, errorFn, dataLevel = 'v1') => {
    new Fetch(
        `training/speaking/plans/${id}/teachers/can_appointment`,
        'get',
        data,
        fn,
        errorFn,
        dataLevel
    );
};
export {
    myAccountInfo,
    trainingPlansApi,
    trainingSchedules,
    trainingPlansTeacher,
    trainingAppointmentListApi,
    trainingAppointmentCreateApi,
    trainingAppointmentInfoApi,
    trainCancelAppointmentApi,
    trainingFeedBackApi,
    trainingCanAppointment,
};