<template>
  <div class="myCourse_box">
    <div class="record_box">
      <div class="record" v-for="item in myCourseData" :key="item.id">
        <div class="title_box">
          <div class="left">
            <p class="title">{{ item.category }}</p>
            <p
              :class="
                item.status === '已预约'
                  ? 'state2'
                  : item.status === '已完成'
                  ? 'state1'
                  : 'state'
              "
            >
              {{ item.status }}
            </p>
          </div>
          <p class="time">
            上课时间：<span>{{ item.deal_started_at }}</span>
          </p>
        </div>
        <div class="case">
          <div class="left">
            <div class="teacher">
              <p>授课老师</p>
              <p class="enName">{{ item.teacher }}</p>
            </div>
            <div class="score" v-if="item.categoryID !=='实用英语'">
              <p>口语分数</p>
              <p class="num">{{ item.score }}</p>
            </div>
          </div>
          <div class="right">
            <!-- 已完成   -->

            <div
              class="complete"
              v-if="item.status === '已完成' && item.sub_status !== 22"
            >
              <div @click="RaiseBtn(item)" v-if="!item.commented_at">评价</div>
              <div @click="RaiseBtn(item)" v-else>已评价</div>
              <span v-if="item.video_status <= 3"></span>
              <div
                v-if="item.video_status === 2"
                @click="openPage(`/course/training/replay/${item.id}`)"
              >
                查看回放
              </div>
              <div
                v-if="item.video_status === 1 || item.video_status === 0"
                class="loading"
              >
                回放生成中
              </div>
              <div
                v-if="item.video_status === 3"
                style="color: rgba(0, 0, 0, 0.3)"
              >
                回放已删除
              </div>
              <span></span>
              <div v-if="item.sub_status === 21" @click="handleFeedback(item)">
                查看反馈
              </div>
            </div>

            <!-- 已完成 但是缺席   -->

            <div
              class="absent"
              v-if="item.status === '已完成' && item.sub_status === 22"
            >
              <div>学生缺席</div>
            </div>

            <!-- 已预约   -->
            <div class="make" v-if="item.status === '已预约'">
              <div @click="courseFn(item)">进入教室</div>
              <span></span>
              <div @click="lookDetail(item)">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appRaise
      v-if="RaiseIsShow"
      :RaiseIsShow="RaiseIsShow"
      :itemList="itemList"
      @changeRaise="changeRaise"
      @submitRaise="submitRaise"
    ></appRaise>
    <showRaise
      v-if="showRaise"
      :itemList="itemList"
      :showRaise="showRaise"
      @closeShow="closeShow"
    ></showRaise>
    <submitSucces
      v-if="SuccesIsShow"
      :SuccesIsShow="SuccesIsShow"
      @closeSucces="closeSucces"
    ></submitSucces>
  </div>
</template>

<script>
import appRaise from "@/views/TrainingSpeaking/NewTrainingSpeakingList/myCourse/components/appRaise";
import showRaise from "@/views/TrainingSpeaking/NewTrainingSpeakingList/myCourse/components/showRaise";
import submitSucces from "@/views/TrainingSpeaking/NewTrainingSpeakingList/myCourse/components/submitSucces";
import {
  trainingPlansApi,
  trainingLookDetaulApi,
} from "@/api/newTrainingSpeaking/newTrainingSpeaking.js";
import { openLiveRoom, openPage } from "@/utils/utils";
import _ from "lodash";
export default {
  components: {
    appRaise,
    showRaise,
    submitSucces,
  },
  props: {
    myCourseData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      RaiseIsShow: false,
      showRaise: false,
      SuccesIsShow: false,
      itemList: {},
    };
  },
  methods: {
    // 让滚动条制定
    scrollTop() {
      console.log("置顶了");
      document.querySelector(".record_box").scrollTop = 0;
    },

    triggerGetData() {},

    // 评价点击
    RaiseBtn(row) {
      console.log(row);
      this.itemList = row;
      if (!row.commented_at) {
        this.RaiseIsShow = true;
      } else {
        this.showRaise = true;
      }
    },

    // 评价弹框关闭点击
    changeRaise(val) {
      this.RaiseIsShow = val;
    },

    // 提交评分弹框点击
    submitRaise(obj) {
      console.log(obj);
      let tagsArr = [];
      obj.tags.forEach((item) => {
        tagsArr.push(item.id);
      });
      let params = {
        comment_content: obj.comment_content,
        comment_score: obj.comment_score,
        tags: tagsArr,
      };
      trainingPlansApi("put", this.itemList.id, params, () => {
        this.SuccesIsShow = true;
        this.$parent.getTrainingAppointmentList(2, 1);
      });
      this.RaiseIsShow = false;
    },

    // 展示评分关闭点击
    closeShow(val) {
      this.showRaise = val;
    },

    // 查看详情
    lookDetail(row) {
      trainingLookDetaulApi(row.id, "", (res) => {
        const resInit = JSON.parse(localStorage.getItem("configInit"));
        const limitObj = resInit.text.find((item) => item.title === "限制时间");
        res.row = row;
        res.limitTime = limitObj.content;
        console.log(res);
        this.$emit("changelookDetaul", res);
      });
    },

    // 评价成功提示框关闭
    closeSucces(val) {
      this.SuccesIsShow = val;
    },

    // 查看反馈
    handleFeedback(row) {
      if (row.category === "雅思") {
        row.category = 1;
      } else if (row.category === "托福") {
        row.category = 2;
      } else {
        row.category = 1;
      }
      localStorage.setItem("appointmentTime", row.deal_started_at);
      this.$router.push({
        path: `/trainingSpeaking/feedback/${row.id}/${row.category}`,
      });
    },
    courseFn(item) {
      let _this = this;
      openLiveRoom(`/training/live/${item.id}`, 3, item.id, this.$route, _this);
    },
    openLiveRoom,
    openPage,
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.myCourse_box {
  padding: 20px;
  width: 100%;
  height: calc(100% - 50px);
  border-radius: 12px;
  .record_box {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    .record {
      padding: 16px 20px;
      margin-bottom: 12px;
      width: 884px;
      height: 123px;
      background-color: #f5f7fa;
      border-radius: 12px;
      &:last-child {
        margin-bottom: 0;
      }

      .title_box {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        margin-bottom: 12px;
        width: 100%;
        height: 37px;
        font-weight: 700;
        border-bottom: 1px solid #e2e2e2;
        .left {
          display: flex;
          align-items: center;
          .title {
            margin-right: 10px;
          }
          .state {
            padding: 0 6px;
            font-size: 12px;
            font-weight: 400;
            color: #000;
            transform: scale(0.9);
            background-color: #999;
          }
          .state1 {
            padding: 0 6px;
            font-size: 12px;
            font-weight: 400;
            color: #27c07d;
            transform: scale(0.9);
            background-color: #e9fcef;
          }
          .state2 {
            padding: 0 6px;
            font-size: 12px;
            font-weight: 400;
            color: #fb8416;
            transform: scale(0.9);
            background-color: #fff7ed;
          }
        }
        .time {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.3);
          span {
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .case {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 42px;
        .left {
          display: flex;
          .teacher {
            text-align: left;
            margin-right: 45px;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.7);
            .enName {
              margin-top: 14px;
              font-weight: 700;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.9);
            }
          }

          .score {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.7);
            .num {
              margin-top: 14px;
              font-weight: 700;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.9);
            }
          }
        }

        .right {
          .complete {
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
              font-size: 14px;
              color: #29d087;
              &:hover {
                cursor: pointer;
              }
            }
            span {
              margin: 0 16px;
              width: 1px;
              height: 16px;
              background-color: #ebebeb;
            }
            .loading {
              color: #b4ebd6;
            }
          }

          .absent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.3);
              &:hover {
                cursor: pointer;
              }
            }
          }

          .make {
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
              font-size: 14px;
              color: #29d087;
              &:hover {
                cursor: pointer;
              }
            }

            span {
              margin: 0 16px;
              width: 1px;
              height: 16px;
              background-color: #ebebeb;
            }
          }
        }
      }
    }
  }
  // 隐藏滚动条
  .record_box::-webkit-scrollbar {
    display: none;
  }
}
</style>
