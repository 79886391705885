export default {
  props: {
    scoreList: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.accountInfo = JSON.parse(localStorage.getItem('accountInfo'))
  },
  data() {
    return {
      accountInfo: {},
      state: '全部'
    }
  },
  methods: {
    handleCommand(command) {
      console.log(command)
      if(command == 0) {
        this.state = '全部'
        this.$parent.changePage = 1
        this.$parent.status = ''
        this.$parent.scoreTopFn()
        this.$parent.getTrainingAppointmentList()
      }
      if(command == 1) {
        this.state = '已预约'
        this.$parent.changePage = 1
        this.$parent.status = '1'
        this.$parent.scoreTopFn()
        this.$parent.getTrainingAppointmentList()
      }

      if(command == 2) {
        this.state = '已完成'
        this.$parent.changePage = 1
        this.$parent.status = '2'
        this.$parent.scoreTopFn()
        this.$parent.getTrainingAppointmentList()
      }
    }
  }
}