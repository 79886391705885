export default {
  props: {
    scoreList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      planItem: {},
      firstRender: true
    }
  },

  computed:{
    lastScore() {
      if(this.scoreList[0]?.last_score.toString().indexOf('.') > -1) {
        return this.scoreList[0]?.last_score
      }else {
        return this.scoreList[0]?.last_score + '.0'
      }
    },

    maxScore() {
      if(this.scoreList[0]?.max_score.toString().indexOf('.') > -1) {
        return this.scoreList[0]?.max_score
      }else {
        return this.scoreList[0]?.max_score + '.0'
      }
    },

    avgScore() {
      if(this.scoreList[0]?.avg_score.toString().indexOf('.') > -1) {
        return this.scoreList[0]?.avg_score
      }else {
        return this.scoreList[0]?.avg_score + '.0'
      }
    },
    changeLastScore() {
      if(this.planItem) {
        if(this.planItem?.last_score >= 0) {
          if(this.planItem?.last_score.toString().indexOf('.') > -1) {
            return this.planItem?.last_score
          }else {
            return this.planItem?.last_score + '.0'
          }
        }
      }else {
        return false
      }
    },
    changeMax_score() {
      if(this.planItem) {
        if(this.planItem?.max_score >= 0) {
          if(this.planItem?.max_score.toString().indexOf('.') > -1) {
            return this.planItem?.max_score
          }else {
            return this.planItem?.max_score + '.0'
          }
        }
      }else {
        return false
      }
    },

    changeAvgScore() {
      if(this.planItem) {
        if(this.planItem?.avg_score >= 0) {
          if(this.planItem?.avg_score.toString().indexOf('.') > -1) {
            return this.planItem?.avg_score
          }else {
            return this.planItem?.avg_score + '.0'
          }
        }
      }else {
        return false
      }
    }
  },
  watch: {
    scoreList(val) {
      if(val.length > 0) {
        if(this.firstRender){
          this.handleCommand(val[0])
          this.firstRender = false
        }
      }
    }
  },
  methods: {
    handleCommand(command) {
      console.log(command, this.scoreList)
      this.planItem = command
      this.$emit('changeCategory', command)
    }
  }
}
