<template>
  <div class="cancel_box">
    <div class="record_box">
      <div class="record" v-for="item in cancelCourseDate" :key="item.id">
        <div class="title_box">
          <p class="title">{{ item.category }}</p>
          <p class="state">{{ item.status }}</p>
        </div>
        <div class="time">
          <p>
            上课时间：<span>{{ startedTime(item) }}</span>
          </p>
        </div>
        <div class="time">
          <p>
            取消时间：<span>{{ canceledTiem(item) }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    cancelCourseDate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    startedTime(row) {
      return moment(row.started_at * 1000).format("YYYY-MM-DD HH:mm:ss");
    },

    canceledTiem(row) {
      return moment(row.canceled_at * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.cancel_box {
  padding: 20px;
  width: 100%;
  height: calc(100% - 50px);
  border-radius: 12px;

  .record_box {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    .record {
      padding: 16px 20px;
      margin-bottom: 12px;
      width: 884px;
      height: 137px;
      background-color: #f5f7fa;
      border-radius: 12px;
      &:last-child {
        margin-bottom: 0;
      }

      .title_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        margin-bottom: 18px;
        width: 100%;
        height: 37px;
        font-weight: 700;
        border-bottom: 1px solid #e2e2e2;
        .state {
          padding: 0 6px;
          font-size: 12px;
          transform: scale(0.9);
          color: #f85251;
          background: #fff4f4;
        }
      }
      .time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 23px;
        p {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.3);
          span {
            color: rgba(0, 0, 0, 0.7);
          }
          strong {
            font-weight: 700;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.9);
            margin: 0 4px;
          }
        }

        div {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .record_box::-webkit-scrollbar {
    display: none;
  }
}
</style>
