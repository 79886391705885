<template>
  <div>
    <el-dialog
      :visible.sync="SuccesIsShow"
      @close="handleClose">
      <div class="stateImg">
        <img src="https://tcdn.papaen.com/assets/mini/papa/cancel-success.png" alt="">
      </div>
      <p class="text">评价成功</p>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    SuccesIsShow: {
      type: Boolean
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleClose() {
      this.$emit('closeSucces', false)
    }
  }
}
</script>

<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .stateImg {
    width: 60px;
    height: 60px;
    img {
      width: 100%;
    }
  }

  .text {
    font-size: 12px;
    color: rgba(0,0,0,0.7);
    margin-top: 9px;
  }

  ::v-deep .el-dialog {
    margin-top: 35vh !important;
    width: 135px;
    height: 135px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .el-dialog__header {
    padding: 0 !important;
  }

  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }

  ::v-deep .el-dialog__footer {
    padding: 0 !important; 
  }

  ::v-deep .el-dialog__headerbtn {
    top: 10px !important;
    right: 10px !important;

  }
</style>