import * as Api from '@/api/trainingSpeaking/trainingSpeaking'
import message from '@/views/TrainingSpeaking/NewTrainingSpeakingList/makeCourse/appointment/components/message.vue'
import { userInfoApi } from '@/api/newTrainingSpeaking/newTrainingSpeaking.js'
import { formatMoment } from '@/utils/utils'
import moment from 'moment'


export default {
  components: {
    message
  },
  props: {
    appointmentHight: {
      type: String
    }
  },
  data () {
    return {
      currentPlan: '',
      isFilterPlan: false,
      plans: [],
      teachers: [],
      canAppointmentTeacherIds: [],
      currentTeacher: 'none',
      tz: '',
      tzAbs: '',
      timeRangeList: [],
      scheduleList: [],
      week: ['日', '一', '二', '三', '四', '五', '六'],
      choseDate: '',
      chooseTimeItem: {},
      originalTeachers: [],
      originalTimeArr: [],
      originalIndex: '',
      messageIsShow: false,

    }
  },

  mounted () {
    this.tz = this.getTimezone()
    this.tzAbs = Math.abs(this.tz)  // 时区绝对值   因为东区为负
    this.init()
  },
  computed: {
    lastTeacherId () {
      return this.currentPlan.last_teacher_id || ''
    },
    tableHeight () {
      return 48 * this.timeRangeList.length + 'px'
    },
  },
  methods: {
    init () {
      this.getPlans()
    },
    getPlans () {
      return Api.trainingPlansApi('', ( res ) => {
        if (res.length > 1) {
          this.canFilterPlan = true
        }
        this.currentPlan = res[ 0 ]
        this.plans = res
        this.getTeachers()
        this.$emit('scoreArr', this.plans)

      })
    },
    getTeachers () {
      Api.trainingPlansTeacher(this.currentPlan.id, '', ( res ) => {
        this.originalTeachers = res
        this.formatTeacherList(res)
      })
    },
    getCanAppointmentTeachers ( timeSign ) {
      let params = {
        date: timeSign === 1 ? this.choseDate - 86400 : this.choseDate,
        moment: this.originalIndex,
      }
      Api.trainingCanAppointment(
          this.currentPlan.id,
          params,
          ( res ) => {
            this.canAppointmentTeacherIds = res.teacher_ids
            this.formatTeacherList()
          }
      )
    },

    getSchedules () {
      let params = {
        teacher_id: this.currentTeacher.id
      }
      Api.trainingSchedules(this.currentPlan.id, params, async ( res ) => {
        // this.timeRangeList = this.produceTimeRangeArr(res.start, res.end)
        this.produceTimeRangeArr(res.start, res.end)
        this.formatSchedules(res)
      })
    },

    formatTeacherList ( teachers ) {
      teachers = teachers || this.originalTeachers
      let freeTeacherArr = []
      let busyTeacherArr = []
      let lastTeacher = []
      let _teacherArr = []

      teachers.forEach(teacher => {
        if (this.currentPlan.last_teacher_id && teacher.id === this.currentPlan.last_teacher_id) {
          if (this.currentTeacher === 'none') {
            this.currentTeacher = teacher
          }
          if (this.canAppointmentTeacherIds.length > 0) {
            if (this.canAppointmentTeacherIds.includes(teacher.id + '')) {
              teacher.is_showTag = true
              teacher.is_free = true
            } else {
              teacher.is_showTag = false
              teacher.is_free = false
            }
          } else {
            teacher.is_showTag = false
            teacher.is_free = true
          }
          lastTeacher.push(teacher)
        } else {
          if (this.canAppointmentTeacherIds.length > 0) {
            if (this.canAppointmentTeacherIds.includes(teacher.id + '')) {
              teacher.is_free = true
              teacher.is_showTag = true
              freeTeacherArr.push(teacher)
            } else {
              teacher.is_free = false
              teacher.is_showTag = false
              busyTeacherArr.push(teacher)
            }
          } else {
            teacher.is_free = true
            teacher.is_showTag = false
            freeTeacherArr.push(teacher)
          }
        }
      })
      let teacherObj = {
        avatar: 'All',
        id: 0,
        is_free: true,
        name: '所有老师'
      }
      freeTeacherArr.splice(0, 0, teacherObj)
      _teacherArr = lastTeacher.concat(freeTeacherArr, busyTeacherArr)
      this.teachers = _teacherArr
      if (this.currentTeacher.id === 0) {
        this.currentTeacher = ''
      } else if (this.currentTeacher !== '') {
        this.getSchedules()
      }
    },

    formatSchedules ( schedule ) {
      let tz = this.tz
      let scheduleStr = ''
      let appointmentStr = ''
      schedule.moments.forEach(item => {
        scheduleStr += item.schedules
        appointmentStr += item.appointments
      })
      let needLength = 0
      //
      if (tz > 0) {
        needLength = Math.abs(tz - (-8)) * 2
        let preStr = this.produceZero(48 - needLength)
        let afterStr = this.produceZero(needLength)
        scheduleStr = preStr + scheduleStr + afterStr
        appointmentStr = preStr + appointmentStr + afterStr
        let addMoment = {
          date: schedule.moments[ schedule.moments.length - 1 ].date + 86400,
          schedules: '',
          appointments: ''
        }
        schedule.moments.push(addMoment)
        schedule.moments.forEach(( moment, index ) => {
          moment.schedules = scheduleStr.substr(index * 48, 48)
          moment.appointments = appointmentStr.substr(index * 48, 48)
        })
      } else {
        if (tz >= -8) {
          needLength = (tz+8) * 2
          let preStr = needLength === 0 ? '' : this.produceZero(48 - needLength)
          let afterStr = needLength === 0 ? '' : this.produceZero(needLength)
          scheduleStr = preStr + scheduleStr + afterStr
          appointmentStr = preStr + appointmentStr + afterStr
          schedule.moments.forEach(( moment, index ) => {
            if(tz>-8){
              moment.date = moment.date - 86400 + ((tz +8 )* 3600)
            }
            moment.schedules = scheduleStr.substr(index * 48, 48)
            moment.appointments = appointmentStr.substr(index * 48, 48)
          })
        } else {
          let addMoment = {
            date: schedule.moments[ 6 ].date + 86400,
            schedules: '',
            appointments: ''
          }
          schedule.moments.push(addMoment)
          needLength = Math.abs(tz - (-8)) * 2
          let preStr = this.produceZero(needLength)
          let afterStr = this.produceZero(48 - needLength)
          scheduleStr = preStr + scheduleStr + afterStr
          appointmentStr = preStr + appointmentStr + afterStr
          schedule.moments.forEach(( moment, index ) => {
            moment.schedules = scheduleStr.substr(index * 48, 48)
            moment.appointments = appointmentStr.substr(index * 48, 48)
          })
        }
      }
      this.formatData(schedule.start, schedule.end, schedule.moments)
      return schedule

    },

    formatData ( start, end, moments ) {
      let tz = this.tz
      let tzAbs = this.tzAbs
      let dateObj = new Date()
      let tomorry = new Date(dateObj.getTime() + 86400000).getDate()
      let today = dateObj.getDate()
      this.timeRangeList = []
      moments.forEach(( item, itemIndex ) => {
        let arr = []
        for (let i = 0; i < 48; i++) {
          let obj = {}
          let h = Math.floor(i / 2)
          if (i % 2 === 1) {
            obj = {
              range: `${h < 10 ? '0' + h : h}:30`,
              index: i,
              is_open: item.schedules[ i ] === '0' ? false : true,
              is_appointment: item.appointments[ i ] === '0' ? false : true,
              disabled: item.date * 1000 + (h * 60 + 30) * 60000 < this.currentTime,

            }
          } else {
            obj = {
              range: `${h < 10 ? '0' + h : h}:00`,
              index: i,
              is_open: item.schedules[ i ] === '0' ? false : true,
              is_appointment: item.appointments[ i ] === '0' ? false : true,
              disabled: item.date * 1000 + (h * 60) * 60000 < this.currentTime,
            }
          }
          if (tz > 0) {

            if (obj.index > (end - tz * 2)) {
              obj.originalIndex = obj.index + tz * 2 + start
              obj.timeSign = -1
              arr.push(obj)
            } else if (obj.index <= (end - (start + (tz * 2)))) {
              obj.originalIndex = obj.index - tz * 2
              obj.timeSign = 1
              arr.push(obj)
            }

          } else {
            if (tz > -8) {
              if (obj.index <= (end - (tz - (-8)) * 2) && obj.index >= tzAbs * 2) {
                obj.timeSign = 1
                arr.push(obj)
              }
            } else if (tz == -8) {
              if (obj.index <= (end - (tz - (-8)) * 2) && obj.index >= tzAbs * 2) {
                arr.push(obj)
              }
            } else {
              if (obj.index >= tzAbs * 2) {
                obj.timeSign = -1
                arr.push(obj)
              } else if (obj.index < ((tzAbs - 8) * 2)) {
                obj.timeSign = 1
                arr.push(obj)
              }
            }


          }

        }
        // item.data = Object.assign({}, arr)
        item.data = arr
                item.fullDate = this.formatMoment(item.date, 'YYYY-MM-DD', true)
        let weekDay = this.week[ new Date(item.date * 1000).getDay() ]
        let date = this.formatMoment(item.date, 'DD', true)
        let preStr = ''
        if (date == today) {
          preStr = '今天'
        } else {
          preStr = tomorry == date ? '明天' : '周' + weekDay
        }
        preStr += '<br>'

        item.dateShow = preStr + this.formatMoment(item.date, 'MM', true) + '月' + date + '日'

      })
      this.timeRangeList = moments[ 0 ].data
      this.scheduleList = moments
      return moments
    },

    produceTimeRangeArr ( start, end ) {
      let arr = []
      for (let i = start; i <= end; i++) {
        let obj = {}
        let h = Math.floor(i / 2)

        if (i % 2 === 1) {
          obj = {
            range: `${h < 10 ? '0' + h : h}:30`,
            index: i,
          }
        } else {
          obj = {
            range: `${h < 10 ? '0' + h : h}:00`,
            index: i,
          }
        }
        arr.push(obj)
      }
      this.originalTimeArr = arr
      return arr
    },
    produceZero ( length ) {
      let str = ''
      for (let i = 0; i < length; i++) {
        str += 0
      }
      return str
    },

    handleChoosePlan ( plan ) {
      this.currentPlan = plan
      this.getTeachers()
    },
    handleChooseTeacher ( teacher ) {
      this.currentTeacher = teacher
      if (teacher.is_showTag) {

      } else {
        this.getSchedules()
      }
    },
    handleChooseTime ( date, item ) {
      if (date === this.choseDate && item.index === this.chooseTimeItem.index) {
        this.choseDate = ''
        this.chooseTimeItem = ''
        this.canAppointmentTeacherIds = []
        this.formatTeacherList()

        return
      }
      this.choseDate = date

      let ymd = this.formatMoment(this.choseDate, 'YYYY-MM-DD', true)
      let timerStr = this.moment((ymd + ' ' + item.range)).utcOffset(8).format('HH:mm')
      let moment = this.originalTimeArr.filter(item => {
        if (item.range === timerStr) {
          return item.index
        }
      })
      this.originalIndex = moment[ 0 ].index
      this.choseDate = date
      this.chooseTimeItem = item
      if (this.currentTeacher.id === 0 || this.currentTeacher === '') {
        this.getCanAppointmentTeachers(item.timeSign)
      }

    },
    okHandleSubmit () {
      let obj = {
        appointmentInfoTeacher: this.currentTeacher,
        appointmentInfoDate: formatMoment(this.choseDate, 'YYYY-MM-DD', true) + ' ' + this.chooseTimeItem.range,
        appointmentInfoPlan: this.currentPlan,
        date: this.choseDate,
        originalIndex: this.originalIndex,
        timeSign: this.chooseTimeItem.timeSign
      }
      this.$emit('nextStep', obj)
    },
    getTimezone () {
      return new Date().getTimezoneOffset() / 60

    },
    formatMoment,
    moment,

    // 点击下一步弹出信息填写弹框
    handleSubmit () {
      if (this.$store.state.user.userInfo.wechat && this.$store.state.user.userInfo.en_name) {
        this.okHandleSubmit()
        return
      }
      this.messageIsShow = true
    },

    // 点击叉号 或 旁边关闭弹框
    offPop ( val ) {
      this.messageIsShow = val
    },

    // 点击信息弹框的下一步触发
    nextOffPop ( val ) {
      this.messageIsShow = val
      this.okHandleSubmit()
    }
  },
  watch: {
    currentPlan: {
      deep: true,
      handler: function ( newVal ) {
        this.$emit('setScore', newVal)
      }
    }
  }
}
