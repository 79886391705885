import { render, staticRenderFns } from "./submitSucces.vue?vue&type=template&id=7ccb2fec&scoped=true"
import script from "./submitSucces.vue?vue&type=script&lang=js"
export * from "./submitSucces.vue?vue&type=script&lang=js"
import style0 from "./submitSucces.vue?vue&type=style&index=0&id=7ccb2fec&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ccb2fec",
  null
  
)

export default component.exports