<template>
  <div class="page_box">
    <div class="left_user" ref="leftRefs">
      <div class="user_header">
        <div class="user_img">
          <img
            :src="userInfo.avatar"
            alt=""
            v-if="userInfo.avatar"
            @error.once="errorImg"
          />
          <img src="../../../assets/img/common/avatar.png" alt="" v-else />
        </div>
        <p class="name">{{ userInfo.nickname }}~</p>
        <p class="text">好的成绩来源于勤奋学习，加油！</p>
      </div>
      <div class="course_bar">
        <div
          :class="courseKey === item.key ? 'changeBar' : ''"
          v-for="item in courseBar"
          :key="item.key"
          @click="courseBarBtn(item)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="explain">
        <p>{{ title }}</p>
        <div v-html="Explain"></div>
      </div>
    </div>
    <div style="width: 924px; height: 100%">
      <div class="grade_box" v-if="courseKey === 1 || courseKey === 2">
        <headerState
          v-if="courseKey === 1"
          :scoreList="scoreList"
          @changeCategory="changeCategory"
        ></headerState>
        <myHeaderState v-if="courseKey === 2"></myHeaderState>
      </div>
      <div class="list_box" ref="listRefs">
        <div
          v-if="
            (myCourseData.length === 0 && courseKey === 2) ||
            (cancelCourseDate.length === 0 && courseKey === 3)
          "
        >
          <NoContent :data="noContentData"></NoContent>
        </div>
        <appointment
          v-if="courseKey === 1"
          ref="appointmentRef"
          @scoreArr="scoreArr"
          @nextStep="nextStep"
          :appointmentHight="appointmentHight"
        ></appointment>
        <courseList
          v-if="courseKey === 2"
          :myCourseData="myCourseData"
          @changelookDetaul="changelookDetaul"
          ref="courseListRef"
        ></courseList>
        <cancelCourse
          v-if="courseKey === 3"
          :cancelCourseDate="cancelCourseDate"
        ></cancelCourse>
        <openRecord
          v-if="courseKey === 4"
          :openRecordData="openRecordData"
        ></openRecord>
        <div class="pagination-box" v-if="total">
          <el-pagination
            v-if="courseKey === 2 || courseKey === 3 || courseKey === 4"
            :current-page="changePage"
            @current-change="handleChangePage"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="15"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="确认预约"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
    >
      <div class="appointmentInfo">
        <div>
          老师 <span>{{ appointmentInfoTeacher }}</span>
        </div>
        <div>
          类型
          <span>{{ appointmentInfoCategory }}</span>
        </div>
        <div>
          上课时间 <span>{{ appointmentInfoDate }}</span>
        </div>
      </div>
      <div class="courseType">
        <h3>课程种类</h3>
        <div class="trainImg">
          <div
            class="trainImgLeft"
            v-for="item in currentTrainingTypes"
            :key="item.id"
          >
            <dl
              @click="clickTrain(item)"
              ref="firstDl"
              class="trainingItemDl"
              :class="{
                'trainingItemDl-active': currentTrainingType === item.id,
              }"
            >
              <dt>
                <h6>{{ item.name }}</h6>
                <span>{{ item.duration }}</span>
              </dt>
              <dd>
                <img :src="item.image_url" alt="" />
              </dd>
            </dl>
            <div class="desc" v-for="desc in item.descriptions" :key="desc">
              <div class="dot"></div>
              <div class="word">{{ desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dropCourse">
        <span>最晚退课时间</span>
        <p>{{ lastTimeText }}</p>
      </div>
      <div class="btn" @click="createAppointment">确认预约</div>
    </el-dialog>
    <succeed
      :succeedDialog="succeedDialog"
      :dialogData="dialogData"
      @offSucceed="offSucceed"
      @cancleoffSucceed="cancleoffSucceed"
    ></succeed>
  </div>
</template>

<script>
import trainingList from "./NewTrainingSpeakingList";
export default trainingList;
</script>

<style lang="less" scoped>
// 课程bar点击高亮类
.changeBar {
  position: relative;
  color: #29d087;
  background: #f8fefa;
  font-weight: 700;
  border-radius: 12px;
  &::before {
    position: absolute;
    top: 22px;
    right: -25px;
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    background-color: #fff;
    content: "";
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.page_box {
  // height: 857px;
  display: flex;
  .left_user {
    display: inline-block;
    padding: 30px 20px;
    margin-right: 16px;
    width: 260px;
    height: 100%;
    border-radius: 12px;
    text-align: left !important;
    background-color: #fff;
    .user_header {
      margin: 0 auto;
      padding-top: 20px;
      width: 210px;
      height: 164px;
      .user_img {
        position: relative;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .name {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
      }
      .text {
        margin-top: 26px;
        text-align: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .course_bar {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      width: 220px;
      height: 268px;
      border-bottom: 1.5px solid #e8e8e8;
      div {
        flex: 1;
        text-align: center;
        line-height: 61px;
        font-size: 14px;
        border-radius: 12px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .explain {
      width: 220px;
      // height: 324px;
      padding: 16px;
      border-radius: 12px;
      background-color: #f4f7fa;
      p {
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
      }
      div {
        font-size: 12px;
        line-height: 20px;
        white-space: break-spaces;
      }
    }
  }

  .grade_box {
    margin-bottom: 16px;
    width: 100%;
    height: 80px;
    border-radius: 12px;
    background-color: #fff;
  }

  .list_box {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
  }
}

.appointmentInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  margin-left: 20px;

  div {
    margin-bottom: 6px;

    span {
      position: absolute;
      left: 110px;
      font-weight: bold;
      color: #333333;
      font-size: 14px;
    }
  }
}

.courseType {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  h3 {
    display: flex;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    font-family: PingFang SC;
  }

  .trainImg {
    margin-top: 14px;
    display: flex;
    justify-content: flex-start;
    .trainingItemDl {
      border: 1px solid #e6e6e6;
    }
    .trainingItemDl-active {
      border: 2px solid #29d087;
    }

    .desc {
      display: flex;
      align-items: center;
      margin-top: 6px;

      .dot {
        width: 5px;
        height: 5px;
        background: #29d087;
      }

      .word {
        display: flex;
        margin-left: 4px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        letter-spacing: 0.4px;
      }
    }

    dl {
      width: 140px;
      height: 120px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      box-sizing: border-box;

      dt {
        margin-top: 15px;

        h6 {
          margin: 0;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        span {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }

      dd {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 81px;
          height: 52px;
        }
      }
    }
  }
}

.dropCourse {
  width: 320px;
  height: 68px;
  margin-top: 19px;
  background: #f5f5f5;
  border-radius: 4px;
  margin-left: 20px;
  span {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    display: flex;
    margin: 9px 0 0 9px;
  }

  p {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: left;
    margin: 4px 0 0 9px;
  }
}

.btn {
  margin: 0 auto;
  width: 160px;
  height: 40px;
  background: #29d087;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}

::v-deep .el-dialog {
  border-radius: 12px;
}

.pagination-box {
  text-align: left;
  padding: 0 0 20px 14px;
}
</style>
