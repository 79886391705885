var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"open_box"},[_c('div',{staticClass:"record_box"},_vm._l((_vm.openRecordData),function(item,index){return _c('div',{key:index,staticClass:"record"},[_c('div',{staticClass:"title_box"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.channel_source))]),_c('p',{staticClass:"num"},[(
              item.expired_at * 1000 - 1296000000 < new Date().getTime() &&
              !item.is_void &&
              item.remaining_count !== 0 &&
              item.expired_at * 1000 > new Date().getTime()
            )?_c('span',[_vm._v("有次数即将过期")]):_vm._e(),_vm._v(_vm._s(item.purchase_count)+"次 ")])]),_c('div',{staticClass:"time"},[_c('p',[_vm._v(" 上课时间："),_c('span',[_vm._v(_vm._s(_vm.createdFn(item)))])]),_c('p',[_vm._v(" 已使用"),_c('strong',[_vm._v(_vm._s(item.purchase_count - item.remaining_count))]),_vm._v("次 ")])]),_c('div',{staticClass:"time"},[_c('p',[_vm._v(" 过期时间："),_c('span',[_vm._v(_vm._s(_vm.expiredFn(item)))])]),(item.is_void && item.remaining_count !== 0)?_c('p',[_vm._v(" 已作废"),_c('strong',[_vm._v(_vm._s(item.remaining_count))]),_vm._v("次 ")]):(
            !item.is_void &&
            item.expired_at * 1000 < new Date().getTime() &&
            item.remaining_count !== 0
          )?_c('p',[_vm._v(" 已过期"),_c('strong',[_vm._v(_vm._s(item.remaining_count))]),_vm._v("次 ")]):(item.remaining_count !== 0)?_c('p',[_vm._v(" 待使用"),_c('strong',[_vm._v(_vm._s(item.remaining_count))]),_vm._v("次 ")]):_c('p')])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }