import * as Api from '@/api/trainingSpeaking/trainingSpeaking'
import { getTypeApi, getLiveConfigApi } from '@/api/live/live'
import {
  openRecordApi,
  cancelMake,
} from '@/api/newTrainingSpeaking/newTrainingSpeaking'
import headerState from './makeCourse/headerState/headerState.vue'
import appointment from './makeCourse/appointment/appointment.vue'
import openRecord from '@/views/TrainingSpeaking/NewTrainingSpeakingList/openRecord/openRecord'
import cancelCourse from '@/views/TrainingSpeaking/NewTrainingSpeakingList/cancelCourse/cancelCourse'
import myHeaderState from '@/views/TrainingSpeaking/NewTrainingSpeakingList/myCourse/headerState/headerState.vue'
import courseList from '@/views/TrainingSpeaking/NewTrainingSpeakingList/myCourse/courseList/courseList.vue'
import succeed from '@/views/TrainingSpeaking/NewTrainingSpeakingList/makeCourse/appointment/components/succeed.vue'
import NoContent from '@/components/NoContent/NoContent'
import { formatMoment } from '@/utils/utils'
import { mapState } from 'vuex'
import AES from '@/utils/AES'

let Base64 = require('js-base64').Base64
export default {
  components: {
    headerState,
    appointment,
    openRecord,
    cancelCourse,
    myHeaderState,
    courseList,
    succeed,
    NoContent,
  },
  created () {
    getTypeApi({}, ( res ) => {
      localStorage.setItem('TrainingConfigType', JSON.stringify(res))
    })
  },
  mounted () {
    this.configInitText()
    this.courseBarBtn(this.courseBar[ 0 ])
    this.accountInfoFn()
  },
  data () {
    return {
      succeedDialog: false,
      dialogVisible: false,
      scoreList: [],
      grade_boxIsShow: true,
      courseBar: [
        {
          key: 1,
          value: '预约课程',
          title: '预约须知',
          label: '',
        },
        {
          key: 2,
          value: '我的课程',
          title: '上课须知',
          label: '',
        },
        {
          key: 3,
          value: '已取消课程',
          title: '上课须知',
          label: '',
        },
        {
          key: 4,
          value: '开通记录',
          title: '课时须知',
          label: '预约时系统将自动消耗更临近过期时间的课次哦~',
        },
      ],
      noContentData: {
        pic: 'course_pic',
        tip: '暂无课程',
      },
      courseKey: 1, //默认选中预约课程
      Explain: '',
      title: '',
      subParams: {
        type: '',
        teacherId: '',
        date: '',
        moment: '',
      },
      myCourseData: [], // 我的课程 数据列表
      mypagination: {}, // 我的课程 links列表
      cancelCourseDate: [],
      cancelpagination: {},
      openRecordData: [],
      openPagination: {},
      changePage: 1,
      dialogData: {},
      status: '',

      lastTimeText: '',
      appointmentInfoTeacher: '',
      appointmentInfoDate: '',
      appointmentInfoCategory: '',
      appointmentInfoPlanId: '',
      appointmentInfoTeacherId: '',
      appointmentHight: '',
      currentTrainingTypes: [],
      currentTrainingType: '',

      //   总页数
      total: 0,
    }
  },
  computed: {
    ...mapState({
      userInfo: ( state ) => state.user.userInfo,
    }),
  },
  methods: {
    // 用户头像损坏
    errorImg ( event ) {
      event.currentTarget.src = require('@/assets/img/common/avatar.png')
    },
    // 点击课程bar
    courseBarBtn ( val ) {
      this.courseKey = val.key
      this.total = 0
      if (val.key === 1) {
        this.$refs?.appointmentRef?.init()
        this.Explain = val.label
        this.title = val.title
        this.changePage = 1
      } else if (val.key === 2) {
        this.Explain = val.label
        this.title = val.title
        this.status = ''
        this.changePage = 1
        this.getTrainingAppointmentList()
      } else if (val.key === 3) {
        this.Explain = val.label
        this.title = val.title
        this.changePage = 1
        this.getCancelCourse(3)
      } else if (val.key === 4) {
        this.Explain = val.label
        this.title = val.title
        this.changePage = 1
        this.getopenRecord()
      }
    },

    // 传来的练习分数
    scoreArr ( arr ) {
      this.scoreList = arr
    },

    // 点击下一步
    nextStep ( obj ) {
      this.dialogVisible = true
      this.appointmentInfoTeacher = obj.appointmentInfoTeacher.name
      this.appointmentInfoDate = obj.appointmentInfoDate
      this.appointmentInfoCategory = obj.appointmentInfoPlan.category.name
      this.appointmentInfoPlanId = obj.appointmentInfoPlan.id
      this.appointmentInfoTeacherId = obj.appointmentInfoTeacher.id
      this.subParams.date = obj.date
      this.subParams.moment = obj.originalIndex
      this.subParams.teacherId = obj.appointmentInfoTeacher.id
      this.subParams.timeSign = obj.timeSign

      let training_speaking_last_time = JSON.parse(
          localStorage.configInit
      ).text.find(( item ) => item.command === 'training_speaking_last_time')

      let lastTime = new Date((obj.date - 100800) * 1000)
      let lastYear = lastTime.getFullYear()
      let month = lastTime.getMonth() + 1
      let day = lastTime.getDate()
      let hour = lastTime.getHours()
      let formateDate = `${lastYear}年${month}月${day}日 ${hour}: 59`
      this.lastTimeText = training_speaking_last_time.content.replace(
          '%s',
          formateDate
      )
    },

    handleClose () {
      this.subParams.type = ''
      this.dialogVisible = false
      this.$refs.firstDl.style.border = '1px solid #e6e6e6'
      this.$refs.secondDl.style.border = '1px solid #e6e6e6'
    },

    clickTrain ( item ) {
      this.subParams.type = item.id
      this.currentTrainingType = item.id
    },

    createAppointment () {
      let that = this
      const timeSign = this.subParams.timeSign
      let params = {
        type: this.subParams.type,
        teacher_id: this.subParams.teacherId,
        date: timeSign === 1 ? this.subParams.date - 86400 : this.subParams.date ,
        moment: this.subParams.moment,
      }
      Api.trainingAppointmentCreateApi(
          this.appointmentInfoPlanId,
          params,
          ( res ) => {
            this.dialogVisible = false
            that.getAppointmentInfo(res.id)
          }
      )
    },

    getAppointmentInfo ( teacher_id ) {
      Api.trainingAppointmentInfoApi(teacher_id, '', ( res ) => {
        const resInit = JSON.parse(localStorage.getItem('configInit'))
        const limitObj = resInit.text.find(( item ) => item.title === '限制时间')
        res.limitTime = limitObj.content
        this.dialogData = res
        this.appointmentDialogTime = formatMoment(
            res.started_at,
            'YYYY-MM-DD HH:mm',
            true
        )
        this.succeedDialog = true
      })
    },
    // 成功预约弹框关闭
    offSucceed ( val ) {
      this.succeedDialog = val
    },

    // 取消预约

    cancleoffSucceed ( obj ) {
      console.log(obj)
      cancelMake(obj.id, {}, () => {
        this.getTrainingAppointmentList()
        this.succeedDialog = obj.isShow
        this.$message.success('取消预约成功')
      })
    },

    // 我的课程数据请求
    getTrainingAppointmentList ( s, p ) {
      let params = {
        status: s || this.status,
        page: p || this.changePage,
      }
      Api.trainingAppointmentListApi(params, ( res, pagination ) => {
        this.total = pagination.total || 0
        res.forEach(( item ) => {
          const typeObj = JSON.parse(
              localStorage.getItem('TrainingConfigType')
          )
          const typesObj = typeObj.speaking_appointment.types.find(
              ( k ) => k.id === item.type
          )
          item.categoryID = item.category
          item.category = `${item.category}${typesObj.name}`
          item.deal_started_at = formatMoment(
              item.started_at,
              'YYYY-MM-DD HH:mm',
              true
          )
          if (item.status == 1) {
            item.status = '已预约'
            item.score = '-'
          } else if (item.status == 2) {
            item.status = '已完成'
            if (item.sub_status !== 21) {
              item.score = '-'
            }
          } else if (item.status == 3) {
            item.status = '已取消'
            item.score = '-'
            if (item.sub_status === 31) {
              item.sub_status = '老师缺席'
            } else if (item.sub_status === 32) {
              item.sub_status = '关闭'
            } else if (item.sub_status === 33) {
              item.sub_status = '学生取消'
            }
          }
        })
        this.myCourseData = res
        this.mypagination = pagination

        if (!this.total) {
          this.lefUsertRefsFn()
        }
      })
    },

    // 已取消课程数据请求
    getCancelCourse ( status ) {
      let params = {
        status,
        page: this.changePage,
      }
      Api.trainingAppointmentListApi(params, ( res, pagination ) => {
        this.total = pagination.total || 0
        res.forEach(( item ) => {
          const typeObj = JSON.parse(
              localStorage.getItem('TrainingConfigType')
          )
          const typesObj = typeObj.speaking_appointment.types.find(
              ( k ) => k.id === item.type
          )
          item.category = `${item.category}${typesObj.name}`
          item.deal_started_at = formatMoment(
              item.started_at,
              'YYYY-MM-DD HH:mm',
              true
          )
          if (item.status === 1) {
            item.status = '已预约'
            item.score = '-'
          } else if (item.status === 2) {
            item.status = '已完成'
            if (item.sub_status === 21) {
              item.status = '已完成'
            }
            if (item.sub_status === 22) {
              item.sub_status = '学生缺席'
              item.score = '-'
            }
          } else if (item.status === 3) {
            item.status = '已取消'
            item.score = '-'
            if (item.sub_status === 31) {
              item.sub_status = '老师缺席'
            } else if (item.sub_status === 32) {
              item.sub_status = '关闭'
            } else if (item.sub_status === 33) {
              item.sub_status = '学生取消'
            }
          }
        })
        this.cancelCourseDate = res
        this.cancelpagination = pagination
        if (!this.total) {
          this.lefUsertRefsFn()
        }
      })
    },

    // 开通课程记录数据请求
    getopenRecord () {
      openRecordApi(
          {
            page: this.changePage,
          },
          ( res, pagination ) => {
            this.total = pagination.total || 0
            res.forEach(( item ) => {
              if (item.channel_source === 1) {
                item.channel_source = '官方店购买'
              } else if (item.channel_source === 2) {
                item.channel_source = '课程赠送'
              } else if (item.channel_source === 3) {
                item.channel_source = '福利赠送'
              } else if (item.channel_source === 4) {
                item.channel_source = '雅思VIP权益'
              } else if (item.channel_source === 5) {
                item.channel_source = '其他'
              }
            })
            this.openRecordData = res
            this.openPagination = pagination
            if (!this.total) {
              this.lefUsertRefsFn()
            }
          }
      )
    },

    pageAdd () {
      if (this.mypagination.next === null) return
      this.changePage++
    },

    handleChangePage ( page ) {
      this.changePage = page
      if (this.courseKey === 2) {
        this.getTrainingAppointmentList()
      } else if (this.courseKey === 3) {
        this.getCancelCourse(3)
      } else if (this.courseKey === 4) {
        this.getopenRecord()
      }
    },

    changelookDetaul ( data ) {
      this.succeedDialog = true
      this.dialogData = data
    },

    scoreTopFn () {
      this.$refs.courseListRef.scrollTop()
    },

    changeCategory ( val ) {
      this.$refs.appointmentRef.handleChoosePlan(val)
      this.setCurrentTrainingType(val.category.id)
    },

    setCurrentTrainingType ( category ) {
      const typeObj = JSON.parse(localStorage.getItem('TrainingConfigType'))
      let target = typeObj.speaking_plan_categories.find(
          ( k ) => k.category === category
      )
      this.currentTrainingTypes = []
      typeObj.speaking_appointment.types.forEach(( item ) => {
        if (target.appointment_types.includes(item.id)) {
          this.currentTrainingTypes.push(item)
        }
      })
    },
    // 账户信息
    accountInfoFn () {
      Api.myAccountInfo('', ( res ) => {
        console.log(res)
        localStorage.setItem('accountInfo', JSON.stringify(res))
      })
    },
    // 直播配置

    // 预约和我的课程须知
    configInitText () {
      let textArr = JSON.parse(localStorage.getItem('configInit')).text
      this.courseBar.forEach(( item ) => {
        if (item.key === 1) {
          const obj = textArr.find(
              ( k ) => k.command === 'training_speaking_notice'
          )
          item.label = obj.content
        }
        if (item.key === 2 || item.key === 3) {
          const obj = textArr.find(
              ( k ) => k.command === 'training_speaking_note'
          )
          item.label = obj.content
        }
      })
    },

    // 获取左侧高度
    lefUsertRefsFn () {
      this.$refs.listRefs.style.height = 760 + 'px' // 先初始化左边栏高度

      if (this.courseKey === 1) {
        this.$refs.listRefs.style.height =
            this.$refs.leftRefs.offsetHeight - 96 + 'px'
        this.appointmentHight =
            this.$refs.leftRefs.offsetHeight - 96 - 69 - 98 - 30 + 'px'
      } else if (this.courseKey === 2) {
        this.$refs.listRefs.style.height =
            this.$refs.leftRefs.offsetHeight - 96 + 'px'
      } else if (this.courseKey === 3) {
        this.$refs.listRefs.style.height =
            this.$refs.leftRefs.offsetHeight + 'px'
      }
    },
  },
}
