<template>
  <div>
    <el-dialog
      :visible.sync="succeedDialog"
      width="480px"
      @close="handleClose">
      <template #title>
        <div class="header_box">
          <div class="left_img">
            <img src="https://tcdn.papaen.com/assets/mini/papa/appointmentSucces.jpg" alt="">
          </div>
          <div class="title">
            <p>您已预约成功！</p>
            <p>您可在 <span>我的课程 - 查看详情</span> 中再次获取预约信息。</p>
          </div>
        </div>
      </template>
      <div class="time_box">
        <p>上课时间：{{courseTime}}</p>
        <p>上课平台：{{dialogData.teacher&&dialogData.teacher.class_platform}}</p>
        <p>授课老师：{{dialogData.teacher&&dialogData.teacher.name}}</p>
        <p>最晚取消预约时间：{{limitTime}}<span v-if="isOverTime">（已超过可取消时间）</span></p>
      </div>
      <div class="attention">
        <p>注意事项</p>
        <div v-html="needAttention"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleBtn" v-if="!isOverTime">取消预约</el-button>
        <el-button type="primary" @click="enterBtn" :style="!isOverTime ? '' : 'margin: 0 auto !important'">进入教室</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { getLiveRoomStatus } from '@/utils/utils.js'
export default {
  props: {
    succeedDialog:{
      type: Boolean
    },
    dialogData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      needAttention: ''
    }
  },
  mounted() {
    const res = JSON.parse(localStorage.getItem('configInit'))
    const obj = res.text.find(item => item.command === 'training_speaking_note')
    this.needAttention = obj.content
  },
  computed: {
    courseTime() {
      return moment(this.dialogData.started_at * 1000).format('YYYY-MM-DD HH:mm')
    },

    limitTime() {
      return moment((this.dialogData.started_at - ((24 - +this.dialogData.limitTime) * 60 * 60)) * 1000 ).format('YYYY-MM-DD HH:mm')
    },

    isOverTime() {
      let limitTime = (this.dialogData.started_at - ((24 - +this.dialogData.limitTime) * 60 * 60)) * 1000
      let atTime = Date.parse(new Date())
      if( limitTime < atTime ) {
        return true
      }else {
        return false
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('offSucceed', false)
    },

    // 取消预约
    cancleBtn() {
      this.$emit('cancleoffSucceed', { ...this.dialogData, isShow: false})
    },

    // 进入教室
    enterBtn() {
      console.log(this.dialogData)
      if(this.dialogData.row) {
        getLiveRoomStatus(this.dialogData.row.id, 3).then((res) => {
          if(res.data.live_room_id) {
            window.open(`/training/live/${this.dialogData.row.id}`)
          }else {
            this.$message.warning('房间号不存在')
          }
        })
      }else {
        getLiveRoomStatus(this.dialogData.id, 3).then((res) => {
          if(res.data.live_room_id) {
            window.open(`/training/live/${this.dialogData.id}`)
          }else {
            this.$message.warning('房间号不存在')
          }
        })
      }
      this.$emit('offSucceed', false)
    }
  }
}
</script>

<style scoped lang="less">
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::v-deep .el-dialog {
    padding: 30px;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
    text-align: left;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
    text-align: left;
  }

  ::v-deep .el-dialog__footer {
    padding: 0;
    text-align: center;
  }

  ::v-deep .el-button {
    padding: 12px 44px;
    border-radius: 25px;
    line-height: 24px;
    border: 1px solid #F85251;
    color: #F85251;
    &:last-child {
      color: #fff;
      border: none;
      border-radius: 24px;
      margin-left: 20px !important;
    }
  }

  ::v-deep .el-icon-close {
    font-size: 24px;
    color: #000 !important;
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  }

  .header_box {
    padding-bottom: 79px;
    margin-bottom: 20px;
    width: 100%;
    height: 58px;
    border-bottom: 1px solid #EBEBEB;
    .left_img {
      display: inline-block;
      margin-right: 20px;
      width: 55px;
      height: 55px;
      img {
        width: 100%;
      }
    }

    .title {
      display: inline-block;
      font-size: 14px;
      color: rgba(0,0,0,0.9);

      p {
        &:first-child {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          margin-bottom: 2px;
        }

        span {
          color: #5FDCA5;
        }
      }
    }
  }

  .time_box {
    font-size: 14px;
    color:rgba(0,0,0,0.6);
    line-height: 22px;
    margin-bottom: 20px;
  }

  .attention {
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0,0,0,0.9);
    background-color: #F4F7FA;
    border-radius: 12px;
    p {
      &:first-child {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
    }
    div {
      font-size: 12px;
      line-height: 24px;
      white-space: break-spaces;
    }
  }
</style>
